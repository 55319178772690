import img1 from "../img/img/1.jpg";
import img2 from "../img/img/2.jpg";
import img4 from "../img/img/4.jpg";
import img5 from "../img/img/5.jpg";
import img6 from "../img/img/6.jpg";
import img7 from "../img/img/7.jpg";
import img8 from "../img/img/8.jpg";
import img9 from "../img/img/9.jpg";
import img10 from "../img/img/10.jpg";
import img11 from "../img/img/11.jpg";
import img12 from "../img/img/12.jpg";
import img13 from "../img/img/13.jpg";
import img14 from "../img/img/14.jpg";
import img15 from "../img/img/15.jpg";
import img16 from "../img/img/16.jpg";
import img17 from "../img/img/17.jpeg";
import img18 from "../img/img/18.jpg";
import img19 from "../img/img/19.jpeg";
import img20 from "../img/img/20.jpg";
import img21 from "../img/img/21.jpeg";
import img22 from "../img/img/22.jpeg";
import img23 from "../img/img/23.jpeg";
import img24 from "../img/img/24.jpeg";
import img25 from "../img/img/25.jpg";
import img26 from "../img/img/26.jpeg";
import img27 from "../img/img/27.jpeg";
import img28 from "../img/img/28.jpg";
import img29 from "../img/img/29.jpg";
import img30 from "../img/img/30.jpg";
import img31 from "../img/img/31.jpeg";
import img32 from "../img/img/32.jpeg";
import img33 from "../img/img/33.jpeg";
import img34 from "../img/img/34.jpeg";
import img35 from "../img/img/35.jpeg";
import img36 from "../img/img/36.jpeg";
import img37 from "../img/img/37.jpeg";
import img38 from "../img/img/38.jpeg";
import img39 from "../img/img/39.jpg";
import img40 from "../img/img/40.jpg";
import img41 from "../img/img/41.jpeg";
import img42 from "../img/img/42.jpeg";
import img43 from "../img/img/43.jpeg";
import img44 from "../img/img/44.jpeg";
import img45 from "../img/img/45.jpg";
import img46 from "../img/img/46.jpg";
// import img47 from "../img/img/47.jpg";
import img48 from "../img/img/48.jpg";

const products = [
  {
    id: 1,
    category: "Wine",
    image: img1,
    brand: "Merit-Brand",
    name: "Mirvari",
    name1: "",
    text: "Вино изготовлено из виноград, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1:"Wine is made from grapes grown in the Shabran region of Azerbaijan on the eastern slopes of the Greater Caucasus Mountains. ",

  },
  {
    id: 2,
    image: img2,
    category: "Wine",
    brand: "Merit-Brand",
    name: "Cici",
    name1: "Red Dry Wine",
    text: "Вино изготовлено из виногда , вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно красного цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, фруктовой выпечкой и идеально дополняет блюдо востойной кухни. ",
    text3:"Температура подачи 16-18 градусов."
  },

  {
    id: 4,
    image: img4,
    category: "Wine",
    brand: "Merit-Brand",
    name: "Tetroni ",
    name1: "Madrasa-Saperavi",
    text: "Вино изготовлено из виногда сортов Мадраса-Саперави, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно красного цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, фруктовой выпечкой и идеально дополняет блюдо востойной кухни. ",
    text3:"Температура подачи 16-18 градусов."
  },
  {
    id: 5,
    image: img5,
    category: "Wine",
    brand: "Merit-Brand",
    name: "Angelo Conti ",
    name1: "Merlot",
    text: "Вино изготовлено из винограда сортов Мерлот, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно рубинового цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, а также с мясом, птицей, сырами и овощами. ",
    text3:"Температура подачи 16-18 градусов."
  },
  {
    id: 6,
    image: img6,
    category: "Wine",
    brand: "Merit-Brand",
    name: "Aylin",
    name1: " Limited Edition",
    text:"Высококачественное калекционное красное сухое вино изготовлено путем пропорционального купажа 6 красных сортов винограда: Мерло, Пиво Нуар Такверди, Кабарде Савиньон, Саперави, Пино Мэнуэр и Мадраса. ",
    text1:"Рекомендуеться подовать с красным мясом, плотными салатами и сырам.",
    text2:"Температура подачи: 14-16°С."
  },
  {
    id: 7,
    category: "Wine",
    image: img7,
    brand: "Merit-Brand",
    name: "Angelo Conti ",
    name1: "Muscat",
    text: "Вино изготовлено из виногда сортов Мусккат, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text2:" Вино якро-золотистого цвета с выразительным отблесками темного золото, с ароматом табака вишни и ореховым послевкусием.",
    text3:" Прекрасно сочитаеться с лососью на гриле",
    text4:"Температура подачи: 9-11°С."
  },
  {
    id: 8,
    category: "Wine",
    image: img8,
    brand: "Merit-Brand",
    name: "Angelo Conti ",
    text1:"Вино этой линейки названы в честь великого итальянского винодела Анжело Конти, который в 19 веке приехал в Азербайджан и стал первым виноделом в этой стране.",
    text2:" Он много путишестновал по миру и однажду попав на восток в Азербайджан, он увидел здесь потенциал для выращивания винограда и решил основать свою винодельню.   ",
    text3:"Современные технологии и традиционные методы производства, которые позволяют сохранить вкус и аромат винограда, а также сохранить все полезные свойства вина."    
  },
  {
    id: 9,
    category: "Wine",
    image: img9,
    brand: "Merit-Brand",
    name: "Tetroni ",
    name1: "Alasan Valley",
    text: "Вино изготовлено из винограда сортов Алазанских долин, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно красноого цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, а также с мясом, птицей, сырами и овощами. ",
    text3:"Температура подачи 16-18 градусов."
  },
  {
    id: 10,
    category: "Wine",
    image: img10,
    brand: "Merit-Brand",
    name: "Angelo Conti ",
    name1: "Cabernet Sauvignon",
    text: "Вино изготовлено из виногда сортов  Каберне Савиньон, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text2:" Вино темно-рубинового цвета с выразительным отблесками темного золото, с ароматом табака вишни и ореховым послевкусием.",
    text3:" Прекрасно сочитаеться с блюдами говятини, ягнятины и различными видами сыров",
    text4:"Температура подачи: 16-18°С."
  },
  {
    id: 11,
    category: "Wine",
    image: img11,
    brand: "Merit-Brand",
    name: "Tetroni ",
    name1: "Alasan Valley",
    text: "Вино изготовлено из винограда сортов Алазанских долин, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно красноого цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, а также с мясом, птицей, сырами и овощами. ",
    text3:"Температура подачи 16-18 градусов."
  },
  {
    id: 12,
    category: "Wine",
    image: img12,
    brand: "Merit-Brand",
    name: "Angelo Conti ",
    name1: "Traminer",
    text: "Вино изготовлено из виногда сортов Трайнер, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text2:" Вино темно-рубинового цвета с выразительным отблесками темного золото, с ароматом табака вишни и ореховым послевкусием.",
    text3:" Прекрасно сочитаеться с блюдами говятини, ягнятины и различными видами сыров",
    text4:"Температура подачи: 10-12°С."
  },
  {
    id: 13,

    image: img13,
    category: "Wine",
    brand: "Merit-Brand",
    name: "Aylin ",
    name1: "Limited Edition",
    text:"Высококачественное калекционное красное сухое вино изготовлено путем пропорционального купажа 6 красных сортов винограда: Мерло, Пиво Нуар Такверди, Кабарде Савиньон, Саперави, Пино Мэнуэр и Мадраса. ",
    text1:"Рекомендуеться подовать с красным мясом, плотными салатами и сырам.",
    text2:"Температура подачи: 14-16°С."
  },
  {
    id: 14,
    category: "Wine",
    image: img14,
    brand: "Merit-Brand",
    name: "Shabran ",
    name1: "Cabernet Sauvignon & Merlot",
    text:"Вино изготоылено из винограда сорта Cabernet Sauvignon и Merlot выращеннного в регионе Шабран в Азербайджане на восточных склонах Кавказа.",
    text1:"Вино красного цвета со слаженным фруктовым ароматом, с нотой черной смородины  и бархатистым вкусом.",
    text2:"Рекомендуеться подовать с красным мясом, плотными салатами и сырам.",
    text3:"Температура подачи 16-18 градусов."
  },
  {
    id: 15,
    category: "Wine",
    image: img15,
    brand: "Merit-Brand",
    name: "Shabran ",
    name1: "Saperavi & Madrasa",
    text: "Вино изготовлено из винограда сортов Саперави и Мадраса, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно фиолетового цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, а также с мясом, птицей, сырами и овощами. ",
    text3:"Температура подачи 16-18 градусов."
  },
  {
    id: 16,
    category: "Wine",
    image: img16,
    brand: "Merit-Brand",
    name: "Shabran",
    text: "Вино изготовлено из винограда , вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1:"Wine is made from grapes grown in the Shabran region of Azerbaijan on the eastern slopes of the Greater Caucasus Mountains. ",


  },
  {
    id: 17,
    category: "Vodka",
    image: img17,
    brand: "Merit-Brand",
    name: "Level ",

    text: "Level – это водка, которая имеет свой особенный характер. Это премиальный алкогольный напиток от популярного шведского бренда, который давно полюбился как отечественному, так и зарубежному покупателю по всему миру.",
  },
  {
    id: 18,

    image: img18,
    category: "Vodka",
    brand: "Merit-Brand",
    name: "Belarusskaya ",

    text: "Водка Белорусская – это водка, которая имеет свой особенный характер. Это премиальный алкогольный напиток от популярного шведского бренда, который давно полюбился как отечественному, так и зарубежному покупателю по всему миру.",
  },
  {
    id: 19,
    category: "Wine",
    image: img19,
    brand: "Merit-Brand",
    name: "Pomegranate Wine",
    text: "Вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно красного цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, фруктовой выпечкой и идеально дополняет блюдо востойной кухни. ",
    text3:"Температура подачи 16-18 градусов."
  },
  {
    id: 20,

    image: img20,
    category: "Vodka",
    brand: "Merit-Brand",
    name: "Organic ",

    text: "Водка Органик – это водка, которая имеет свой особенный характер. Это премиальный алкогольный напиток от популярного шведского бренда, который давно полюбился как отечественному, так и зарубежному покупателю по всему миру.",
  },
  {
    id: 21,
    category: "Wine",
    image: img21,
    brand: "Merit-Brand",
    name: "Madrasa Wine",
    text: "Вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно красного цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, фруктовой выпечкой и идеально дополняет блюдо востойной кухни. ",
    text3:"Температура подачи 16-18 градусов."
  },
  {
    id: 22,
    category: "Wine",
    image: img22,
    brand: "Merit-Brand",
    name: "Сapital of Legends",
    text:"Вина этой линейки названы в честь города Шабран",
    text1:" Древний город шабран некогда бвл крупным и политеческим центром.",
    text2:"Вокруг шабрана до сих пор всевозможные легенды. Согласно одной из них здесь зарыто золото которое могли перевести на 40 верблюдов.",
    text3:" в 2011 году город Шабран был провозглошон Столицей Легенд Азербайджана."
  },
  {
    id: 23,
    category: "Wine",
    image: img23,
    brand: "Merit-Brand",
    name: "Сapital of Legends",
    name1: " Muscat",
    text: "Вино изготовлено из виногда сортов Мусккат, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text2:" Вино якро-золотистого цвета с выразительным отблесками темного золото, с ароматом табака вишни и ореховым послевкусием.",
    text3:" Прекрасно сочитаеться с лососью на гриле",
    text4:"Температура подачи: 9-11°С."

  },
  {
    id: 24,
    category: "Wine",
    image: img24,
    brand: "Merit-Brand",
    name: "Сapital of Legends",
    text:"Вина этой линейки названы в честь города Шабран",
    text1:" Древний город шабран некогда бвл крупным и политеческим центром.",
    text2:"Вокруг шабрана до сих пор всевозможные легенды. Согласно одной из них здесь зарыто золото которое могли перевести на 40 верблюдов.",
    text3:" в 2011 году город Шабран был провозглошон Столицей Легенд Азербайджана."
  },
  {
    id: 25,
    category: "Wine",
    image: img25,
    brand: "Merit-Brand",
    name: "Сapital of legends",
    name1: " Madrasa ",
    text: "Вино изготовлено из виногда сортов Мадраса, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно красного цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, а также с мясом, птицей, сырами и овощами. ",
    text3:"Температура подачи 16-18 градусов."
  },
  {
    id: 26,
    image: img26,
    brand: "Merit-Brand",
    name: "Сapital of Legends",
    name1: "Kabirne Sovini",
    text: "Вино изготовлено из виногда сортов Каберне Савиньони, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно красного цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, а также с мясом, птицей, сырами и овощами. ",
    text3:"Температура подачи 16-18 градусов."
  },
  {
    id: 27,
    category: "Wine",
    image: img27,
    brand: "Merit-Brand",
    name: "Сapital of Legends",
    name1: "Sharton",
    text: "Вино изготовлено из виногда сортов Шартон, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно красного цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, а также с мясом, птицей, сырами и овощами. ",
    text3:"Температура подачи 16-18 градусов."
  },
  {
    id: 28,
    category: "Wine",
    image: img28,
    brand: "Merit-Brand",
    name: "Mirvari",
    name1: "Cabernet Sauvignon - Merlot",
    text: "Вино изготовлено из виногда сортов Каберне Савиньон - Мерлот, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно красного цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, а также с мясом, птицей, сырами и овощами. ",
    text3:"Температура подачи 16-18 градусов."
  },
  {
    id: 29,
    image: img29,
    category: "Сognac",
    brand: "Merit-Brand",
    name: "Azerbaijan",
    name1: "Сognac",
    text: "Вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно красного цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, фруктовой выпечкой и идеально дополняет блюдо востойной кухни. ",
    text3:"Температура подачи 16-18 градусов."
  },
  {
    id: 30,
    image: img30,
    category: "Сognac",
    brand: "Merit-Brand",
    name: "Shabran  ",
    name1: "Gold",
    text:"Вино темно-янтарого цвета с выразительным отблесками темного золото, с ароматом табака вишни и ореховым послевкусием.",
    text2:"Рекомендуеться подавать с блюдами из мяса, птицы, сыров, а также в качестве аперитива.",
    text3:"Температура подачи: 16-18°С."
  },
  {
    id: 31,
    image: img31,
    brand: "Merit-Brand",
    name: "Traminer",
    text: "Вино изготовлено из виногда сортов Траминер, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно красного цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, фруктовой выпечкой и идеально дополняет блюдо востойной кухни. ",
    text3:"Температура подачи 16-18 градусов."
  },
  {
    id: 32,
    image: img32,
    brand: "Merit-Brand",
    name: "Cabernet Franc Rose",
    text: "Вино изготовлено из виногда сортов Кабернет, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно красного цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, фруктовой выпечкой и идеально дополняет блюдо востойной кухни. ",
    text3:"Температура подачи 16-18 градусов."
  },
  {
    id: 33,
    image: img33,
    brand: "Merit-Brand",
    name: "Syrah",
    text: "Вино изготовлено из виногда сортов Сурах, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно красного цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, фруктовой выпечкой и идеально дополняет блюдо востойной кухни. ",
    text3:"Температура подачи 16-18 градусов."
  },
  {
    id: 34,

    image: img34,
    brand: "Merit-Brand",
    name: "Sauvignon Blanc",
    text: "Вино изготовлено из виногда сортов Савильон Блан, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно красного цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, фруктовой выпечкой и идеально дополняет блюдо востойной кухни. ",
    text3:"Температура подачи 16-18 градусов."
  },
  {
    id: 35,
    category: "Wine",
    image: img35,
    brand: "Merit-Brand",
    name: "Tetroni ",
    name1: "Sauvignon Blanc",
    text: "Вино изготовлено из винограда сортов  Савиньон Блан, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно рубиногово цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, а также с мясом, птицей, сырами и овощами. ",
    text3:"Температура подачи 10-12 градусов."
  },
  {
    id: 36,
    category: "Wine",
    image: img36,
    brand: "Merit-Brand",
    name: "Tetroni ",
    name1: "Saperavi",
    text: "Вино изготовлено из винограда сортов  Саперави, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно рубиногово цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, а также с мясом, птицей, сырами и овощами. ",
    text3:"Температура подачи 16-18 градусов."
  },
  {
    id: 37,
    category: "Wine",
    image: img37,
    brand: "Merit-Brand",
    name: "Tetroni ",
    name1: "",
    text:"Тетрони - Сказочные кавказские вина ",
    text1: "Кажеться они впитали в себя всю соль родной земли, всю теплоту солнца и всю мудрость древних культур. ",
    text2:"Tetroni - Fabulous Caucasian Wines",
    text3:"It seems that they absorbed all the salt of their native land, all the warmth of the sun and all the wisdom of ancient cultures."
  },
  {
    id: 38,
    category: "Wine",
    image: img38,
    brand: "Merit-Brand",
    name: "Tetroni ",
    name1: "Shardone",
    text: "Вино изготовлено из винограда сортов  Шардоне, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно светно-соломенного цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, а также с мясом, птицей, сырами и овощами. ",
    text3:"Температура подачи 10-12 градусов."
  },
  {
    id: 39,
    category: "Wine",
    image: img39,
    brand: "Merit-Brand",
    name: "Angelo Conti ",
    name1: "Sauvignon Blan ",
    text: "Вино изготовлено из виногда сортов Совиньон Блан, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text2:" Вино темно-рубинового цвета с выразительным отблесками темного золото, с ароматом табака вишни и ореховым послевкусием.",
    text3:" Прекрасно сочитаеться с блюдами говятини, ягнятины и различными видами сыров",
    text4:"Температура подачи: 10-12°С."

  },
  {
    id: 40,
    category: "Wine",
    image: img40,
    brand: "Merit-Brand",
    name: "Angelo Conti ",
    name1: "Shiraz",
    text: "Вино изготовлено из виногда сортов  Шираз, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text2:" Вино темно-рубинового цвета с выразительным отблесками темного золото, с ароматом табака вишни и ореховым послевкусием.",
    text3:" Прекрасно сочитаеться с блюдами говятини, ягнятины и различными видами сыров",
    text4:"Температура подачи: 16-18°С."
  },
  {
    id: 41,
    category: "Wine",
    image: img41,
    brand: "Merit-Brand",
    name: "Karabakh ",
    name1: "2015",
  },
  {
    id: 42,
    category: "Wine",
    image: img42,
    brand: "Merit-Brand",
    name: "Shabran ",
    name1: "Granate",
    text:"Вино темно-янтарого цвета с выразительным отблесками темного золото, с ароматом табака вишни и ореховым послевкусием.",
    text2:"Рекомендуеться подавать с блюдами из мяса, птицы, сыров, а также в качестве аперитива.",
    text3:"Температура подачи: 16-18°С."

  },
  {
    id: 43,
    category: "Wine",
    image: img43,
    brand: "Merit-Brand",
    name: "Shabran ",
    name1: "Gold Granate",
    text:"Вино темно-янтарого цвета с выразительным отблесками темного золото, с ароматом табака вишни и ореховым послевкусием.",
    text2:"Рекомендуеться подавать с блюдами из мяса, птицы, сыров, а также в качестве аперитива.",
    text3:"Температура подачи: 16-18°С."
  },
  {
    id: 44,
    category: "Wine",
    image: img44,
    brand: "Merit-Brand",
    name: "Matrasa",
    text: "Вино изготовлено из виногда сортов Мадраса, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно красного цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, фруктовой выпечкой и идеально дополняет блюдо востойной кухни. ",
    text3:"Температура подачи 16-18 градусов."

  },
  {
    id: 45,
    category: "Wine",
    image: img45,
    brand: "Merit-Brand",
    name: "Shabran ",
    name1: "Merlot",
    text: "Вино изготовлено из виногда сортов  Мерлот, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: " Вино насышенно рубиного цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2:"Превосходно сочетаеться с мясными блюдами а также с мясом, птицей, сырами и овощами.",
    text3:"Температура подачи 16-18 градусов."
    

  },
  {
    id: 46,
    category: "Wine",
    image: img46,
    brand: "Merit-Brand",
    name: "Shabran ",
    name1: "Saperavi-Merlot",
    text: "Вино изготовлено из виногда сортов Саперави и Мерло, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно фиолетового цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, а также с мясом, птицей, сырами и овощами. ",
    text3:"Температура подачи 16-18 градусов."
  },
  // {
  //   id: 47,
  //   category: "Vodka",
  //   image: img47,
  //   brand: "Merit-Brand",
  //   name: "Lido Vodka",
  // },
  {
    id: 48,
    category: "Wine",
    image: img48,
    brand: "Merit-Brand",
    name: "Tetroni ",
    name1: "Alasan Valley",
    text: "Вино изготовлено из винограда сортов Алазанских долин, вырашенных в регионе Шабран в Азербайджане. На восточных склонах гор Большого Кавказа.  ",
    text1: "Вино насышенно рубиногово цвета, с нежным ароматом свежих фруктов и польным вкусом с приятной сладостью. ",
    text2: "Замичательно сочитаеться с десертами, а также с мясом, птицей, сырами и овощами. ",
    text3:"Температура подачи 16-18 градусов."
  },

];

export default products;
